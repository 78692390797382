import { Breadcrumb, BreadcrumbProps } from "antd";
import React from "react";
import { createPortal } from "react-dom";

export function PortaledBreadcrumb(props: BreadcrumbProps) {
    const element = document.getElementById("breadcrumb-portal");
    if (!element) {
        return null;
    }

    return <>{createPortal(<Breadcrumb {...props} />, element)}</>;
}
