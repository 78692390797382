import { z } from "zod";

export const messageRole = z.enum(["user", "assistant"]);

export const message = z.object({
  id: z.string(),
  role: messageRole,
  content: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type Message = z.infer<typeof message>;
export type MessageRole = z.infer<typeof messageRole>;
