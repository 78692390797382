import z from 'zod'

export function paginatedSearchQuery<
    FilterType extends z.ZodTypeAny,
    SortType extends z.ZodTypeAny,
    IncludeType extends z.ZodTypeAny,
>({ filters, sort, include }: { filters: FilterType; sort: SortType; include?: IncludeType }) {
    return z.object({
        page: z.number().optional(),
        size: z.number().optional(),
        include: include ? include.array().optional() : z.string().array().optional(),
        sort: sort.optional(),
        filters: filters.optional(),
    })
}

export const searchQuery = paginatedSearchQuery({
    filters: z.record(z.string(), z.array(z.any())),
    sort: z.any(),
})

export type SearchQuery = z.infer<typeof searchQuery>

export type PaginationResponse<T> = {
    data: T[]
    meta: {
        currentPage: number
        from: number | null
        lastPage: number
        path: string
        perPage: number
        to: number | null
        total: number
    }
}

export function paginated<ItemType extends z.ZodTypeAny>(schema: ItemType) {
    return z.object({
        data: z.array(schema),
        meta: z.object({
            currentPage: z.number(),
            from: z.number().nullable(),
            lastPage: z.number(),
            path: z.string(),
            perPage: z.number(),
            to: z.number().nullable(),
            total: z.number(),
        }),
    })
}
