import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link, Outlet, createFileRoute } from '@tanstack/react-router'
import { Card, Flex, Typography } from 'antd'
import React from 'react'
import { ConversationCard } from '../components/conversation-card'
import { upperFirst } from '../util'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_auth/campaigns/$campaignId/$type')({
    component: Component,
})

function Component() {
    const { t } = useTranslation()

    const { type } = Route.useParams()

    return (
        <Card size="small">
            <Flex
                gap="middle"
                align="start"
            >
                <div>
                    <Link
                        to="/campaigns/$campaignId/conversations"
                        params={{
                            campaignId: '0650720f-21e8-4c62-9ae2-4af9862aa34d',
                        }}
                    >
                        <Flex
                            align="center"
                            gap="small"
                            className="mb-3"
                        >
                            <ArrowLeftOutlined />
                            <Typography.Title className="text-sm mb-0">
                                {upperFirst(type)} {t('conversations')}
                            </Typography.Title>
                        </Flex>
                    </Link>

                    <Flex
                        vertical
                        gap="small"
                        className="min-w-[230px]"
                    >
                        <ConversationCard
                            name="Test"
                            compatibility={50}
                        />
                        <ConversationCard
                            name="Test"
                            compatibility={50}
                        />
                        <ConversationCard
                            name="Test"
                            compatibility={50}
                        />
                    </Flex>
                </div>
                <Outlet />
            </Flex>
        </Card>
    )
}
