import {ExperimentOutlined, RightOutlined} from "@ant-design/icons";
import {useMutation} from "@tanstack/react-query";
import {Button, Flex, Input, Space, Typography} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {ChatMessage, Message} from "./message";

export type ChatProps = {
  messages: Message[];
  generateUserAnswer?: () => Promise<string>;
  onMessageAdded: (input: string) => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
};

export function Chat({
  messages,
  disabled = false,
  placeholder,
  generateUserAnswer,
  onMessageAdded,
  ...rest
}: ChatProps) {
  const [input, setInput] = useState("");
  const messageContainer = useRef<HTMLElement>(null);

  const userAnswer = useMutation({
    mutationFn: generateUserAnswer,
  });

  useEffect(() => {
    messageContainer.current?.scrollTo({
      top: messageContainer.current.scrollHeight,
    });
  }, [messages]);

  const addMessage = (input: string) => {
    onMessageAdded(input);
    setInput("");
  };
  return (
    <Flex vertical {...rest}>
      <Flex
        gap="large"
        vertical
        className="h-[500px] overflow-y-scroll pr-6 pb-4 flex"
        ref={messageContainer}
      >
        {messages.map((message, index) => {
          return <ChatMessage key={index} {...message} />;
        })}
      </Flex>

      {disabled && (
        <Typography.Text type="secondary" className="absolute bottom-[70px]">
          eva is typing ...
        </Typography.Text>
      )}

      <Space.Compact block size="large">
        {generateUserAnswer && (
          <Button
            loading={userAnswer.isPending}
            onClick={() => {
              userAnswer.mutateAsync().then((message) => {
                addMessage(message);
              });
            }}
            icon={<ExperimentOutlined />}
          />
        )}
        <Input
          className="rounded-md rounded-r-none"
          value={input}
          onKeyDown={(event) => {
            if (event.key !== "Enter" || disabled) {
              return;
            }

            addMessage(input);
          }}
          onChange={(event) => setInput(event.target.value)}
          placeholder={placeholder}
        />
        <Button
          className="rounded-l-none rounded-md"
          disabled={!!disabled}
          icon={<RightOutlined />}
          onClick={() => addMessage(input)}
        />
      </Space.Compact>
    </Flex>
  );
}
