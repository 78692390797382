import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FormInstance } from 'antd'
import {
    createCampaign,
    createCampaignTraining,
    createCandidate,
    fetchCampaign,
    fetchCampaignOverview,
    fetchCampaigns,
    fetchCampaignTraining,
    fetchCampaignTrainings,
    fetchCandidates,
    fetchUser,
    fetchUsers,
    forgotPassword,
    register,
    resetPassword,
    search,
    updateCampaign,
    updateCampaignTraining,
    verify,
} from './api'
import { addValidationOnError } from './util'
import { UpdateCampaignInput } from './zod/campaign'
import { UpdateCampaignTrainingInput } from './zod/campaign-training'
import { CreateCandidateInput } from './zod/candidate'
import { ResetInput } from './zod/reset'
import { SearchQuery } from './zod/pagination'

export function useCampaign(campaignId: string) {
    return useQuery({
        queryFn: () => {
            return fetchCampaign(campaignId)
        },
        queryKey: ['campaigns', campaignId],
    })
}

export function useCampaigns() {
    return useQuery({
        queryFn: fetchCampaigns,
        queryKey: ['campaigns'],
    })
}

export function useCampaignTrainings(campaignId?: string) {
    return useQuery({
        queryFn: () => {
            return fetchCampaignTrainings(campaignId!)
        },
        queryKey: ['campaigns', campaignId, 'trainings'],
        enabled: !!campaignId,
    })
}

export function useCampaignTraining(campaignId?: string, trainingId?: string) {
    return useQuery({
        queryFn: () => {
            return fetchCampaignTraining(campaignId!, trainingId!)
        },
        queryKey: ['campaigns', campaignId, 'trainings', trainingId],
        enabled: !!trainingId && !!campaignId,
    })
}

export function useCreateCampaign() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: createCampaign,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['campaigns'],
                exact: true,
            })
        },
    })
}

export function useUpdateCampaign(campaignId?: string) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: UpdateCampaignInput) => updateCampaign(campaignId!, data),
        onSuccess: (campaign) => {
            queryClient.invalidateQueries({
                queryKey: ['campaigns'],
                exact: true,
            })

            queryClient.invalidateQueries({
                queryKey: ['search'],
            })

            queryClient.setQueryData(['campaigns', campaign.id], campaign)
        },
    })
}

export function useCreateCampaignTraining(campaignId?: string) {
    const queryClient = useQueryClient()

    const createCampaignTrainingFn = () => {
        return createCampaignTraining({
            campaignId: campaignId!,
        })
    }

    return useMutation({
        mutationFn: createCampaignTrainingFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['campaigns', campaignId, 'trainings'],
                exact: true,
            })
        },
    })
}

export function useUpdateCampaignTraining(campaignId?: string, trainingId?: string) {
    const queryClient = useQueryClient()

    const updateCampaignTrainingFn = (data: Omit<UpdateCampaignTrainingInput, 'campaignId' | 'trainingId'>) => {
        return updateCampaignTraining({
            campaignId: campaignId!,
            trainingId: trainingId!,
            ...data,
        })
    }

    return useMutation({
        mutationFn: updateCampaignTrainingFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['campaigns', campaignId, 'trainings', trainingId],
                exact: true,
            })
        },
    })
}

export function useForgotPassword() {
    return useMutation({
        mutationFn: forgotPassword,
    })
}

export function useResetPassword(token: string, form?: FormInstance) {
    return useMutation({
        mutationFn: (data: ResetInput) => resetPassword(token, data),
        onError: addValidationOnError(form),
    })
}

export function useRegister(form?: FormInstance) {
    return useMutation({
        mutationFn: register,
        onError: addValidationOnError(form),
    })
}

export function useVerify(token: string) {
    return useQuery({
        queryFn: () => verify(token),
        queryKey: ['verify'],
        retry: 0,
    })
}

export function useCreateCandidate(campaignId?: string, form?: FormInstance) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (input: CreateCandidateInput) => createCandidate({ ...input, campaignId: campaignId! }),
        onSuccess() {
            form?.resetFields()
            queryClient.invalidateQueries({
                queryKey: ['campaigns', campaignId, 'candidates'],
                exact: true,
            })

            queryClient.invalidateQueries({
                queryKey: ['campaigns', campaignId, 'overview'],
                exact: true,
            })
        },
        onError: addValidationOnError(form),
    })
}

export function useCandidates(campaignId?: string) {
    return useQuery({
        queryFn: () => {
            return fetchCandidates(campaignId!)
        },
        queryKey: ['campaigns', campaignId, 'candidates'],
    })
}

export function useCampaignOverview(campaignId?: string) {
    return useQuery({
        queryFn: () => {
            return fetchCampaignOverview(campaignId!)
        },
        queryKey: ['campaigns', campaignId, 'overview'],
    })
}

export function useUsers({
    pagination,
}: {
    pagination?: SearchQuery
} = {}) {
    return useQuery({
        queryFn: () => fetchUsers({ pagination }),
        queryKey: ['users', pagination],
        placeholderData: keepPreviousData,
    })
}

export function useSearch(query?: string) {
    return useQuery({
        queryFn: () => search(query!),
        queryKey: ['search', query],
        enabled: !!query,
    })
}

export function useUser(userId: string) {
    return useQuery({
        queryFn: () => {
            return fetchUser(userId)
        },
        queryKey: ['users', userId],
    })
}
