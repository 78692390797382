import axios from 'axios'
import { decamelizeKeys } from 'humps'
import { z } from 'zod'
import { axiosDefaults, baseURL, del, get, patch, post } from './axios'
import { Campaign, campaignOverview, CreateCampaignInput, UpdateCampaignInput } from './zod/campaign'
import {
    campaignTraining,
    CreateCampaignTrainingInput,
    createCampaignTrainingResponse,
    UpdateCampaignTrainingInput,
} from './zod/campaign-training'
import { candidate, CreateCandidateInput } from './zod/candidate'
import { conversation } from './zod/conversation'
import { ForgotInput, forgotResponse } from './zod/forgot'
import { LoginInput, loginResponse } from './zod/login'
import { message } from './zod/message'
import { RegisterInput, registerResponse } from './zod/register'
import { ResetInput } from './zod/reset'
import { user } from './zod/user'
import { paginated, SearchQuery } from './zod/pagination'
import { buildFilters, Filters } from './hooks/use-pagination'
import { campaign } from './campaign/campaign'

export const fetchMe = () => {
    return get(user, '/me')
}

export const login = (data: LoginInput) => {
    return post(loginResponse, '/login', data)
}

export const refresh = (token: string) => {
    return axios
        .create(axiosDefaults)
        .get(baseURL + '/refresh', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => loginResponse.parse(response.data))
}

export const fetchCampaigns = () => {
    return get(z.array(campaign), '/campaigns')
}

export const fetchCampaign = (campaignId: string) => {
    return get(campaign, `/campaigns/${campaignId}`)
}

export const fetchUser = (userId: string) => {
    return get(user, `/users/${userId}`)
}

export const createCampaign = (data: CreateCampaignInput) => {
    return post(campaign, '/campaigns', data)
}

export const updateCampaign = (campaignId: string, data: UpdateCampaignInput) => {
    return patch(campaign, `/campaigns/${campaignId}`, data)
}

export const fetchCampaignTrainings = (campaignId: string) => {
    return get(z.array(campaignTraining), `/campaigns/${campaignId}/trainings`)
}

export const fetchCampaignTraining = (campaignId: string, trainingId: string) => {
    return get(campaignTraining, `/campaigns/${campaignId}/trainings/${trainingId}`)
}

export const generateCampaignTrainingUserAnswer = (campaignId: string, trainingId: string) => {
    return post(z.string(), `/campaigns/${campaignId}/trainings/${trainingId}/generate-user-answer`, {})
}

export const updateCampaignTraining = ({ campaignId, trainingId, ...rest }: UpdateCampaignTrainingInput) => {
    return patch(message, `/campaigns/${campaignId}/trainings/${trainingId}`, rest)
}

export const createCampaignTraining = ({ campaignId, ...rest }: CreateCampaignTrainingInput) => {
    return post(createCampaignTrainingResponse, `/campaigns/${campaignId}/trainings`, rest)
}

export const forgotPassword = (data: ForgotInput) => {
    return post(forgotResponse, '/forgot', data)
}

export const resetPassword = (token: string, data: ResetInput) => {
    return post(forgotResponse, `/reset/${token}`, data)
}

export const register = (data: RegisterInput) => {
    return post(registerResponse, `/register`, data)
}

export const verify = (token: string) => {
    return get(z.string(), `/verify/${token}`)
}

export const createCandidate = ({ campaignId, ...rest }: CreateCandidateInput) => {
    return post(candidate, `/campaigns/${campaignId}/candidates`, rest)
}

export const fetchCandidates = (campaignId: string) => {
    return get(candidate.array(), `/campaigns/${campaignId}/candidates`)
}

export const fetchCampaignOverview = (campaignId: string) => {
    return get(campaignOverview, `/campaigns/${campaignId}/overview`)
}

export const fetchUsers = ({ pagination }: { pagination?: SearchQuery }) => {
    return get(paginated(user), `/users?${toQueryString(pagination || {})}`)
}

export const searchResponse = z.object({
    users: user.array().optional(),
    campaigns: campaign
        .pick({ id: true, title: true, createdAt: true, status: true, updatedAt: true })
        .array()
        .optional(),
    candidates: candidate.array().optional(),
    conversation: conversation.array().optional(),
})

export type SearchResponse = z.infer<typeof searchResponse>

export const search = (query: string) => {
    return get(searchResponse, `/search/${query}`)
}

export function toQueryString({ filters, ...rest }: SearchQuery) {
    return new URLSearchParams({
        ...(decamelizeKeys(rest) as Record<string, string>),
        ...(filters ? buildFilters(decamelizeKeys(filters) as Filters) : {}),
    }).toString()
}
// interface Options {
//   separator: string;
//   encode(string: string): string;
//   encodeBrackets: boolean;
// }

// const buildQuery = (
//   input: any,
//   prefix: string | null | undefined,
//   opts: Options,
// ): string => {
//   prefix = prefix ?? "";
//   const _ = opts.encode;
//   let arr = [];

//   switch (typeof input) {
//     case "object":
//       for (const i in input)
//         if (typeof input[i] !== "undefined" && typeof input[i] !== "function") {
//           if (typeof input[i] === "object")
//             arr.push(
//               buildQuery(input[i], prefix ? `${prefix}[${i}]` : i, opts),
//             );
//           else if (Array.isArray(input))
//             arr.push(prefix ? `${prefix}[]=${_(input[i])}` : _(input[i]));
//           else
//             arr.push(
//               prefix ? `${prefix}[${i}]=${_(input[i])}` : `${i}=${_(input[i])}`,
//             );
//         }
//       break;
//     case "string":
//       arr.push(_(input));
//       break;
//     case "number":
//     case "bigint":
//     case "boolean":
//       arr.push(_(input.toString()));
//       break;
//   }

//   return arr.filter((r) => !!r).join(opts.separator);
// };

// export const queryString = (
//   object: object | string | number | bigint,
//   options?: Partial<Options>,
// ): string | undefined => {
//   if (["object", "string", "number", "bigint"].indexOf(typeof object) < 0)
//     return undefined;

//   // @ts-ignore
//   let opts: Options = Object.assign(
//     {
//       separator: "&",
//       encode: encodeURIComponent,
//       encodeBrackets: false,
//     },
//     options || {},
//   );

//   return opts.encodeBrackets
//     ? opts.encode(buildQuery(object, null, opts))
//     : buildQuery(object, null, opts);
// };
