import { z } from "zod";
import { message } from "./message";

export const conversation = z.object({
  id: z.string(),
  type: z.string(),
  compatibility: z.number(),
  messages: z.array(message).optional(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type Conversation = z.infer<typeof conversation>;
