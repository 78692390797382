import { SearchOutlined } from '@ant-design/icons'
import { Input, InputRef, Modal, ModalProps } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useArrowTabIndex } from '../arrow-tabindex'
import { useSearch } from '../hooks'
import { UserSearchList } from '../user/components/user-search-list'
import { CampaignSearchList } from '../campaign/components/campaign-search-list'

export type SearchProps = { onCancel: () => void } & Omit<ModalProps, 'footer' | 'centered' | 'styles' | 'closable'>

export function Search({ onCancel, open, ...rest }: SearchProps) {
    const searchRef = useRef<InputRef>(null)
    const [query, setQuery] = useState('')
    const search = useSearch(query)
    useArrowTabIndex('.search li')

    const hasResult = Object.keys(search?.data || {}).length > 0

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                searchRef.current!.focus({
                    cursor: 'all',
                })
            }, 200)
        }
    }, [open])

    return (
        <Modal
            centered
            className="search"
            styles={{
                content: {
                    padding: 0,
                },
                footer: {
                    display: 'none',
                },
            }}
            onCancel={onCancel}
            open={open}
            footer={<></>}
            closable={false}
            {...rest}
        >
            <Input
                ref={searchRef}
                size="large"
                value={query}
                onChange={(event) => {
                    setQuery(event.target.value)
                }}
                className={query && hasResult ? 'rounded-b-none' : ''}
                prefix={<SearchOutlined />}
                placeholder="Search..."
            />
            {search.isSuccess && (
                <div>
                    {search.data.users && search.data.users.length > 0 && (
                        <UserSearchList
                            users={search.data.users}
                            onCancel={onCancel}
                        />
                    )}
                    {search.data.campaigns && search.data.campaigns.length > 0 && (
                        <CampaignSearchList
                            campaigns={search.data.campaigns}
                            onCancel={onCancel}
                        />
                    )}
                </div>
            )}
        </Modal>
    )
}
