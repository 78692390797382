import axios, {
    AxiosError,
    AxiosRequestTransformer,
    AxiosResponse,
    AxiosResponseTransformer,
    CreateAxiosDefaults,
} from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { z } from 'zod'
import { refresh } from './api'
import { clearStorage, getToken, isTokenExpired, storeToken } from './auth'
import { notification } from 'antd'
import { SearchQuery } from './zod/pagination'
import { buildFilters, Filters } from './hooks/use-pagination'
import { t } from 'i18next'

export const baseURL = import.meta.env.DEV ? 'http://localhost' : ''

export const axiosDefaults: CreateAxiosDefaults = {
    baseURL,
    transformRequest: [
        (data) => decamelizeKeys(data),
        ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    ],
    transformResponse: [
        ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
        (data) => camelizeKeys(data),
    ],
    headers: {
        'Content-Type': 'application/json',
    },
}

export const api = axios.create(axiosDefaults)

api.interceptors.request.use(
    async function (config) {
        const token = getToken('access')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`

            if (isTokenExpired(token)) {
                const token = getToken('refresh')

                if (!token || isTokenExpired(token)) {
                    clearStorage()
                    window.location.reload()
                    throw new Error('refresh expired, auth required')
                }

                const response = await refresh(token)

                storeToken(response.accessToken, 'access')
                storeToken(response.refreshToken, 'refresh')

                config.headers.Authorization = `Bearer ${response.accessToken}`
            }
        }

        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        if (error instanceof AxiosError) {
            if (error.code === 'ERR_NETWORK') {
                notification.warning({
                    placement: 'top',
                    description: t('Check you internet connectivity'),
                    message: t('Network unreachable'),
                    key: 'ERR_NETWORK',
                })
            }

            if (error.response?.status === 500) {
                notification.error({
                    placement: 'top',
                    type: 'error',
                    description: t('Server error encountered, try again later'),
                    message: t('Server error'),
                    key: '500',
                })
            }

            if (error.response?.status === 429) {
                notification.warning({
                    placement: 'top',
                    description: t('You are sending too many requests, wait a bit and try again'),
                    message: t('Too many requests'),
                    key: '429',
                    duration: 2,
                })
            }
        }

        return Promise.reject(error)
    }
)

export async function get<T>(schema: z.ZodType<T>, path: string) {
    return await api
        .get<z.ZodType<T>>(path)
        .then((response) => schema.parse(response.data))
        .catch((err) => {
            console.error(err)

            throw err
        })
}

export async function post<T, D>(schema: z.ZodType<T>, path: string, data: D) {
    return await api
        .post<z.ZodType<T>, AxiosResponse<z.ZodType<T>>, D>(path, data)
        .then((response) => schema.parse(response.data))
        .catch((err) => {
            console.error(err)

            throw err
        })
}

export async function patch<T, D>(schema: z.ZodType<T>, path: string, data?: D) {
    return await api
        .patch<z.ZodType<T>, AxiosResponse<z.ZodType<T>>, D>(path, data || ({} as D))
        .then((response) => schema.parse(response.data))
        .catch((err) => {
            console.error(err)

            throw err
        })
}

export async function del<T>(schema: z.ZodType<T>, path: string) {
    return await api
        .delete<z.ZodType<T>>(path)
        .then((response) => schema.parse(response.data))
        .catch((err) => {
            console.error(err)

            throw err
        })
}

export function toQueryString({ filters, ...rest }: SearchQuery) {
    return new URLSearchParams({
        ...(decamelizeKeys(rest) as Record<string, string>),
        ...(filters ? buildFilters(decamelizeKeys(filters) as Filters) : {}),
    }).toString()
}
