import { Avatar, AvatarProps } from 'antd'
import React from 'react'
import { User } from '../user'

export type UserAvatarProps = {
    user: User
}

export function UserAvatar({ user }: UserAvatarProps & AvatarProps) {
    return <Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${user.id}`} />
}
