import { Link } from '@tanstack/react-router'
import React from 'react'
import { User } from '../user'
import { LinkPropsChildren } from '@tanstack/react-router/src/link'

type UserLinkProps = {
    id: User['id']
    name?: User['name']
} & React.DOMAttributes<'a'> &
    LinkPropsChildren

export function UserLink({ id, name, children, ...rest }: UserLinkProps) {
    return (
        <Link
            to="/users/$userId"
            params={{
                userId: id,
            }}
            {...rest}
        >
            {name || children}
        </Link>
    )
}
