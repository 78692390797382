import { Link } from '@tanstack/react-router'
import { Badge, List, Switch, theme } from 'antd'
import React from 'react'
import { useUpdateCampaign } from '../hooks'
import { PauseOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { SearchResponse } from '../../api'
import { SearchProps } from '../../components/search'
import { CampaignBadge } from './campaign-badge'

export type CampaignSearchListProps = {
    campaigns: SearchResponse['campaigns']
} & Pick<SearchProps, 'onCancel'>

export function CampaignSearchList({ campaigns, onCancel }: CampaignSearchListProps) {
    const { token } = theme.useToken()

    return (
        <List
            header={<span style={{ padding: `0 ${token.paddingMD}px` }}>Campaigns</span>}
            dataSource={campaigns}
            renderItem={(campaign) => {
                const updateCampaign = useUpdateCampaign(campaign.id)
                return (
                    <Link
                        to="/campaigns/$campaignId/overview"
                        onClick={onCancel}
                        params={{ campaignId: campaign.id }}
                    >
                        <List.Item
                            className="cursor-pointer"
                            tabIndex={0}
                            style={{
                                padding: `${token.paddingSM}px ${token.paddingMD}px`,
                            }}
                            actions={[
                                <Switch
                                    onClick={(_, e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                    onChange={() => {
                                        updateCampaign.mutate({
                                            status: campaign.status === 'active' ? 'inactive' : 'active',
                                        })
                                    }}
                                    loading={updateCampaign.isPending}
                                    checked={campaign.status === 'active'}
                                    checkedChildren={<PauseOutlined />}
                                    unCheckedChildren={<PlayCircleOutlined />}
                                />,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<CampaignBadge status={campaign.status} />}
                                title={campaign.title}
                            />
                        </List.Item>
                    </Link>
                )
            }}
        />
    )
}
