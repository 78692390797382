import * as React from 'react'
import { login as apiLogin } from './api'
import { LoginInput, accessClaims, claims } from './zod/login'
import { User, user } from './zod/user'
import { camelizeKeys } from 'humps'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
export interface AuthContext {
    isAuthenticated: boolean
    login: UseMutationResult<any, any, any, any>
    logout: () => void
    user: User | null
}

const AuthContext = React.createContext<AuthContext | null>(null)
const userKey = 'auth.user'
const accessTokenKey = 'auth.access.token'
const refreshTokenKey = 'auth.refresh.token'

export function tokenClaims(token: string) {
    const claimsData = JSON.parse(atob(token.split('.')[1]))

    return claims.parse(camelizeKeys(claimsData))
}

export function tokenAccessClaims(token: string) {
    const claimsData = JSON.parse(atob(token.split('.')[1]))
    return accessClaims.parse(camelizeKeys(claimsData))
}

function getStoredUser() {
    const item = localStorage.getItem(userKey)

    if (item === null) {
        return item
    }

    return user.parse(JSON.parse(item))
}

export function storeUser(user: User | null) {
    if (user) {
        localStorage.setItem(userKey, JSON.stringify(user))
    } else {
        localStorage.removeItem(userKey)
    }
}

type TokenType = 'access' | 'refresh'

function storageKeyForTokenType(type: TokenType) {
    if (type === 'refresh') {
        return refreshTokenKey
    }

    return accessTokenKey
}

export function getToken(type: TokenType): string | null {
    const tokenValue = localStorage.getItem(storageKeyForTokenType(type))

    if (!tokenValue) {
        return null
    }

    return tokenValue
}

export function storeToken(token: string | null, type: TokenType) {
    if (token) {
        localStorage.setItem(storageKeyForTokenType(type), token)
    } else {
        localStorage.removeItem(storageKeyForTokenType(type))
    }
}

export function isTokenExpired(token: string) {
    const claims = tokenClaims(token)
    return Date.now() >= claims.exp * 1000
}

export function clearStorage() {
    storeToken(null, 'access')
    storeToken(null, 'refresh')
    storeUser(null)
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = React.useState<User | null>(getStoredUser())
    const isAuthenticated = !!user

    const logout = React.useCallback(() => {
        clearStorage()
        setUser(null)
    }, [])

    const login = useMutation({
        mutationFn: ({ email, password, remember }: LoginInput) => {
            return apiLogin({
                email,
                password,
                remember,
            })
        },
        onSuccess: (data) => {
            storeToken(data.accessToken, 'access')
            storeToken(data.refreshToken, 'refresh')

            const { user } = tokenAccessClaims(data.accessToken)
            storeUser(user)
            setUser(user)
        },
    })

    // const login = React.useCallback(async ({ email, password, remember }: LoginInput) => {
    //     const response = await apiLogin({
    //         email,
    //         password,
    //         remember,
    //     })

    //     storeToken(response.accessToken, 'access')
    //     storeToken(response.refreshToken, 'refresh')

    //     const { user } = tokenAccessClaims(response.accessToken)

    //     storeUser(user)
    //     setUser(user)

    //     return user
    // }, [])

    return <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>{children}</AuthContext.Provider>
}

export function useAuth() {
    const context = React.useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
