import { z } from "zod";
import {role} from "./role";
import {paginatedSearchQuery} from "../zod/pagination";

export const user = z.object({
    id: z.string(),
    name: z.string(),
    email: z.string(),
    role,
    emailVerifiedAt: z.coerce.date(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
})

export type User = z.infer<typeof user>

export const userQuery = paginatedSearchQuery({
    filters: z.object({
        name: z.string().array().optional(),
        email: z.string().array().optional(),
        blocked: z.boolean().array().optional(),
        role: role.array().optional(),
    }),
    sort: z.enum(['name', '-name', 'email', '-email', 'role', '-role']),
})

export type UserQuery = z.infer<typeof userQuery>


