import { z } from "zod";
import { candidateStatus } from "./candidate";

const campaignStatus = z.enum(["training", "active", "inactive"]);

export const campaignOverview = z.object({
  totalCandidates: z.number(),
  candidateCounts: z.record(candidateStatus, z.number()),
});

export const campaign = z.object({
  id: z.string(),
  status: campaignStatus,
  title: z.string(),
  offer: z.string(),
  createdAt: z.coerce.date(),
});

export type CampaignStatus = z.infer<typeof campaignStatus>;
export type Campaign = z.infer<typeof campaign>;

export type CreateCampaignInput = Pick<Campaign, "offer" | "title">;
export type UpdateCampaignInput = Partial<
  Pick<Campaign, "status" | "title" | "offer">
>;
