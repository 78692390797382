import { Form, Input, Modal, ModalProps, theme, Typography } from 'antd'
import React, { useState } from 'react'
import { useDeleteCampaign } from '../hooks'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { FormItem } from '../../components/form-item'
import { Campaign } from '../campaign'
import { useTranslation } from 'react-i18next'

type CampaignDeleteModalProps = {
    campaign: Campaign
    close: () => void
} & ModalProps

export function CampaignDeleteModal({ campaign, ...rest }: CampaignDeleteModalProps) {
    const { token } = theme.useToken()
    const { t } = useTranslation()
    const [deleteTitle, setDeleteTitle] = useState('')
    const { mutateAsync, isPending } = useDeleteCampaign()

    const close = () => {
        setDeleteTitle('')
        rest.close()
    }

    return (
        <Modal
            {...rest}
            title={
                <>
                    <ExclamationCircleFilled
                        className="mr-2"
                        size={200}
                        style={{ marginRight: token.marginMD, color: token.colorError }}
                    />
                    {t('Delete campaign')}
                </>
            }
            onCancel={close}
            onOk={() => mutateAsync(campaign.id).then(close)}
            okText={t('Delete campaign')}
            okButtonProps={{
                danger: true,
                loading: isPending,
                disabled: deleteTitle !== campaign.title,
            }}
        >
            <Typography.Text>
                {t(
                    'All data stored in this campaign will be lost Conversations will be deleted, candidates will be deleted.'
                )}
            </Typography.Text>
            <Typography.Paragraph>
                <pre>{campaign.title}</pre>
            </Typography.Paragraph>
            <Form layout="vertical">
                <FormItem
                    label={t('Title of the campaign')}
                    required
                >
                    <Input
                        value={deleteTitle}
                        onChange={(event) => setDeleteTitle(event.target.value)}
                    />
                </FormItem>
            </Form>
        </Modal>
    )
}
