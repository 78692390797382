import { Tag } from 'antd'
import React from 'react'
import { User } from '../user'
import { useTranslation } from 'react-i18next'

export function RoleTag({ role }: { role: User['role'] }) {
    const { t } = useTranslation()

    switch (role) {
        case 'customer':
            return <Tag>{t('roles.customer')}</Tag>
        case 'admin':
            return <Tag color="red">{t('roles.admin')}</Tag>
    }
}
