import { Flex, Typography } from 'antd'
import React, { ReactNode } from 'react'

type PageHeaderProps = {
    title: ReactNode
    prefix?: ReactNode
    onTitleChanged?: (title: string) => void
    children?: ReactNode
}

export function PageHeader({ title, onTitleChanged, children, prefix }: PageHeaderProps) {
    return (
        <div>
            <Flex
                justify="space-between"
                align="center"
            >
                <Flex
                    gap="small"
                    align="center"
                >
                    {prefix}
                    <Typography.Title
                        level={2}
                        className="mb-0 font-normal text-[24px]"
                        contentEditable={!!onTitleChanged}
                        suppressContentEditableWarning
                        onBlur={(e) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onTitleChanged!((e.target as any).innerText)
                        }}
                    >
                        {title}
                    </Typography.Title>
                </Flex>

                {children}
            </Flex>
        </div>
    )
}
