import { useEffect } from 'react'

export function useArrowTabIndex(selector: string) {
    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            let add = 0

            if (event.key === 'ArrowUp') {
                add = -1
            }

            if (event.key === 'ArrowDown') {
                add = 1
            }

            if (add === 0) {
                return
            }

            const elements = [...document.querySelectorAll(selector + '[tabindex="0"]')]

            if (document.activeElement) {
                const nextElement = elements[elements.indexOf(document?.activeElement) + add] as
                    | HTMLInputElement
                    | undefined

                nextElement?.focus()
            }
        }

        document.addEventListener('keydown', listener)

        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [selector])
}
