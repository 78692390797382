import { createFileRoute, Link } from '@tanstack/react-router'
import { Button, Card, Checkbox, Form, Input, Result, Tooltip, Typography } from 'antd'
import React from 'react'
import { useRegister } from '../hooks'
import { FormItem } from '../components/form-item'
import { RegisterInput } from '../zod/register'
import { Trans, useTranslation } from 'react-i18next'
import useForm from '../hooks/use-form'

const { Text, Title } = Typography

export const Route = createFileRoute('/_guest/')({
    component: RegisterComponent,
})

function RegisterComponent() {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const register = useRegister(form)

    const formProps = useForm({
        mutation: register,
    })

    const password = Form.useWatch('password', form)
    const confirmed = Form.useWatch('passwordConfirmation', form)
    const passwordConfirmed = password && confirmed && password === confirmed

    if (register.isSuccess) {
        return (
            <Result
                title={t('Registration successful')}
                subTitle={t(
                    'Before you will be able to login you will have to verify your email, check your email for instructions'
                )}
                status="success"
                extra={
                    <Link to="/login">
                        <Button
                            size="large"
                            type="primary"
                        >
                            {t('Login')}
                        </Button>
                    </Link>
                }
            />
        )
    }

    return (
        <Card>
            <div className="mb-6">
                <Title>{t('Create Account')}</Title>
            </div>
            <Form
                {...formProps}
                name="basic"
                layout="vertical"
                style={{ minWidth: 300 }}
                autoComplete="off"
            >
                <FormItem<RegisterInput>
                    label={t('Name')}
                    name="name"
                    required
                >
                    <Input
                        name="name"
                        placeholder="John Doe"
                    />
                </FormItem>

                <FormItem<RegisterInput>
                    label={t('Email')}
                    name="email"
                    required
                >
                    <Input
                        type="email"
                        name="email"
                        placeholder="john@example.com"
                    />
                </FormItem>

                <FormItem<RegisterInput>
                    label={t('Password')}
                    name="password"
                    required
                >
                    <Input.Password
                        name="password"
                        placeholder="Password"
                    />
                </FormItem>
                <FormItem<RegisterInput>
                    label={t('Confirm password')}
                    name="passwordConfirmation"
                    required
                >
                    <Input.Password
                        name="passwordConfirmation"
                        placeholder="Password"
                    />
                </FormItem>

                <FormItem>
                    <FormItem<RegisterInput>
                        name="agreement"
                        valuePropName="checked"
                        className="mb-0"
                    >
                        <Checkbox name="agreement">
                            {/* refactor this for i18n */}
                            <Trans
                                defaults="By completing this registration, you hereby agree to our <br/><general>General Terms and Conditions</general>, and to our <privacy>Data privacy policy</privacy>"
                                components={{
                                    general: (
                                        <Link
                                            className="text-primary"
                                            to="/forgot"
                                        />
                                    ),
                                    privacy: (
                                        <Link
                                            className="text-primary"
                                            to="/forgot"
                                        />
                                    ),
                                }}
                            />
                        </Checkbox>
                    </FormItem>
                </FormItem>

                <FormItem className="mb-0">
                    <Button
                        loading={register.isPending}
                        block
                        type="primary"
                        htmlType="submit"
                    >
                        {t('Register')}
                    </Button>
                    <div className="mt-5 justify-center flex space-x-1">
                        <Text>{t('If you already have an account, you can')}</Text>
                        <Link
                            className="float-right text-primary"
                            to="/login"
                        >
                            {t('sign in instead')}
                        </Link>
                    </div>
                </FormItem>
            </Form>
        </Card>
    )
}
