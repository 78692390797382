import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { createFileRoute } from '@tanstack/react-router'
import { Avatar, Button, Card, Flex, theme, Typography } from 'antd'
import React, { useState } from 'react'
import { Chat } from '../components/chat/chat'

export const Route = createFileRoute('/_auth/campaigns/$campaignId/$type/$conversationId')({
    component: Component,
})

function Component() {
    const { token } = theme.useToken()
    const [isInfoVisible, setInfoVisible] = useState(false)

    return (
        <>
            <Card
                className="w-full"
                size="small"
                title={
                    <Flex
                        justify="space-between"
                        align="center"
                        className="py-2"
                    >
                        <Flex gap="small">
                            <Avatar
                                size="large"
                                src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`}
                            />
                            <Flex vertical>
                                <Typography.Title
                                    level={5}
                                    className="mb-0"
                                >
                                    H. de Vries
                                </Typography.Title>
                                <Typography.Text type="secondary">Active 20 minutes ago</Typography.Text>
                            </Flex>
                        </Flex>
                        <Button
                            style={{
                                color: token.colorText,
                            }}
                            onClick={() => setInfoVisible(!isInfoVisible)}
                            className="hover:text-primary"
                            ghost
                            icon={isInfoVisible ? <CloseOutlined /> : <InfoCircleOutlined />}
                        />
                    </Flex>
                }
            >
                <Chat
                    messages={[
                        {
                            content:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet condimentum quam. Cras tempor leo nec mattis dapibus. Morbi ultrices volutpat tellus ac vestibulum',
                            role: 'assistant',
                        },
                        {
                            content:
                                'Nullam ullamcorper finibus enim vitae lacinia. Ut semper, magna nec gravida mattis, nisi purus varius arcu, a rutrum ligula sapien id sem. Phasellus gravida porta metus ac maximus. Praesent ac sapien tempus nisi pulvinar condimentum.',
                            role: 'user',
                        },
                    ]}
                    onMessageAdded={() => {}}
                />
            </Card>

            {isInfoVisible && (
                <Flex
                    gap="middle"
                    vertical
                    align="center"
                    className="px-8 text-center"
                >
                    <Avatar
                        size={100}
                        src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`}
                    />
                    <Typography.Title
                        level={5}
                        className="mb-0"
                    >
                        H. de Vries
                    </Typography.Title>
                    <Flex vertical>
                        <Typography.Text type="secondary">23-08-1992</Typography.Text>
                        <Typography.Text type="secondary">Zoetemeer</Typography.Text>
                    </Flex>
                    <Button>Send to HR</Button>
                </Flex>
            )}
        </>
    )
}
