import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { fetchUsers } from './api'
import { UserQuery } from './user'

export function useUsers({
    query,
}: {
    query?: UserQuery
} = {}) {
    return useQuery({
        queryFn: () => fetchUsers({ query }),
        queryKey: ['users', query],
        placeholderData: keepPreviousData,
    })
}
