import { z } from "zod";
import { message } from "./message";
import { campaign } from "./campaign";
import { conversation } from "./conversation";

export const campaignTraining = z.object({
  id: z.string(),
  conversationId: z.string(),
  conversation: conversation.optional(),
  campaign: campaign.optional(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type CampaignTraining = z.infer<typeof campaignTraining>;

export const createCampaignTrainingInput = z.object({
  campaignId: z.string(),
});
export type CreateCampaignTrainingInput = z.infer<
  typeof createCampaignTrainingInput
>;

export const createCampaignTrainingResponse = z.object({
  id: z.string(),
  message,
});

export type CreateCampaignTrainingResponse = z.infer<
  typeof createCampaignTrainingResponse
>;

export const updateCampaignTrainingInput = z.object({
  campaignId: z.string(),
  trainingId: z.string(),
  message: z.string(),
});

export type UpdateCampaignTrainingInput = z.infer<
  typeof updateCampaignTrainingInput
>;
