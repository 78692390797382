import { Button, Flex, Result, ResultProps, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import React from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-router'

export type NetworkErrorsProps = {
    query: UseQueryResult
    result404?: ResultProps
    result500?: ResultProps
}

export function NetworkErrors({ query, result404, result500 }: NetworkErrorsProps) {
    const { t } = useTranslation()

    if (query.isPending) {
        return (
            <Flex
                align="center"
                className="w-full h-96"
                justify="center"
            >
                <Spin
                    indicator={<LoadingOutlined spin />}
                    size="large"
                />
            </Flex>
        )
    }

    if (query.isError) {
        if (axios.isAxiosError(query.error) && query.error.response) {
            const { status } = query.error.response
            if (status === 404) {
                return (
                    <Result
                        status="404"
                        title={t('Not found')}
                        subTitle={t("Sorry, we can't find what you are looking for")}
                        extra={
                            <Link to="/dashboard">
                                <Button type="primary">Dashboard</Button>
                            </Link>
                        }
                        {...result404}
                    />
                )
            }

            if (status === 500) {
                return (
                    <Result
                        status="500"
                        title={t('Whoops, something went wrong')}
                        subTitle={t('There are some problems with your operation.')}
                        extra={
                            <Link to="/dashboard">
                                <Button type="primary">Dashboard</Button>
                            </Link>
                        }
                        {...result500}
                    />
                )
            }
        }

        return (
            <Result
                status="error"
                title={t('There are some problems with your operation.')}
            />
        )
    }
}
