import { z } from "zod";

export const candidateStatus = z.enum([
  "new",
  "conversation",
  "positive",
  "negative",
  "suspect",
]);

export const candidate = z.object({
  id: z.string(),
  campaignId: z.string(),
  name: z.string(),
  phone: z.string(),
  status: candidateStatus,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type Candidate = z.infer<typeof candidate>;
export type CandidateStatus = z.infer<typeof candidateStatus>;

export type CreateCandidateInput = Pick<
  Candidate,
  "campaignId" | "name" | "phone"
>;

export type UpdateCandidateInput = Partial<
  Pick<Candidate, "campaignId" | "name" | "phone">
>;
