import { z } from "zod";

export const role = z.enum(["admin", "customer"]);
export const user = z.object({
  id: z.string(),
  name: z.string(),
  role,
  email: z.string(),
  emailVerifiedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type User = z.infer<typeof user>;
