import { LogoutOutlined, PieChartOutlined, SearchOutlined, UserOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { createFileRoute, Outlet, redirect, useMatchRoute, useNavigate, useRouter } from '@tanstack/react-router'
import { Avatar, Button, Card, Flex, Layout, Menu, theme, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../auth'
import { Search } from '../components/search'
import { Container } from '../components/layout/container'
import { useTranslation } from 'react-i18next'
import logoLarge from '../assets/logo-large.png'

export const Route = createFileRoute('/_auth')({
    beforeLoad: ({ context, location }) => {
        if (!context.auth.isAuthenticated) {
            throw redirect({
                to: '/login',
                search: {
                    redirect: location.href,
                },
            })
        }
    },
    component: AuthLayout,
})

const { Content } = Layout

export function AuthLayout() {
    const { t } = useTranslation()
    const [searchOpen, setSearchOpen] = useState(false)
    const matchRoute = useMatchRoute()
    const navigate = useNavigate()
    const router = useRouter()

    const { user, logout } = useAuth()
    const { token } = theme.useToken()

    const menuItems = [
        {
            key: '/dashboard',
            icon: <PieChartOutlined />,
            label: 'Dashboard',
            prefix: '/dashboard',
        },
        {
            key: '/campaigns',
            icon: <WhatsAppOutlined />,
            label: 'Campaigns',
            prefix: '/campaigns',
        },
        {
            key: '/users',
            icon: <UserOutlined />,
            label: 'Users',
            prefix: '/users',
        },
    ]

    const getSelectedKeys = () => {
        const matchedMenuItem = menuItems.find(({ prefix }) => {
            return matchRoute({ to: prefix, fuzzy: true }) !== false
        })

        return [matchedMenuItem?.key || '/dashboard']
    }

    const selectedKeys = getSelectedKeys()

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if (event.target instanceof HTMLInputElement) {
                return
            }

            if (event.target instanceof HTMLTextAreaElement) {
                return
            }

            if (event.key === '/') {
                event.preventDefault()
                event.stopPropagation()
                setSearchOpen(true)
            }

            return
        })
    })

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content>
                <Container>
                    <img
                        alt="log"
                        className="my-10"
                        width="169"
                        src={logoLarge}
                    />
                    <Card
                        className="shadow-card-sm"
                        styles={{
                            body: {
                                padding: '35px 30px',
                            },
                        }}
                    >
                        <Flex
                            justify="space-between"
                            align="center"
                            className="mb-[35px]"
                        >
                            <Flex
                                gap="middle"
                                align="center"
                            >
                                <Avatar
                                    size="large"
                                    src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${user?.id}`}
                                />

                                <Typography.Title
                                    className="font-medium mb-0"
                                    level={4}
                                >
                                    {t('Hello, {{name}}', { name: user?.name })}
                                </Typography.Title>

                                <div
                                    id="breadcrumb-portal"
                                    className="ml-5"
                                ></div>
                            </Flex>

                            <Flex
                                gap="middle"
                                align="center"
                            >
                                <Card
                                    size="small"
                                    className="min-w-[300px]"
                                    styles={{
                                        body: {
                                            padding: '5px 10px',
                                        },
                                    }}
                                    onClick={() => {
                                        return setSearchOpen(true)
                                    }}
                                >
                                    <span className="relative">
                                        <SearchOutlined />
                                        <Typography.Text
                                            className="ml-2"
                                            type="secondary"
                                        >
                                            {t('Search')}
                                        </Typography.Text>{' '}
                                        <Typography.Text
                                            className="float-right"
                                            keyboard
                                        >
                                            /
                                        </Typography.Text>
                                    </span>
                                </Card>
                                <Search
                                    open={searchOpen}
                                    onCancel={() => setSearchOpen(false)}
                                />

                                <Tooltip title="Sign out">
                                    <Button
                                        ghost
                                        style={{
                                            color: token.colorText,
                                        }}
                                        className="hover:text-primary"
                                        onClick={() => {
                                            logout()
                                            //   ????????????????
                                            setTimeout(() => {
                                                router.invalidate().finally(() => {
                                                    return navigate({ to: '/login' })
                                                })
                                            }, 0)
                                        }}
                                        icon={<LogoutOutlined />}
                                    />
                                </Tooltip>
                            </Flex>
                        </Flex>
                        <Flex gap="large">
                            <Card
                                styles={{
                                    body: {
                                        padding: '35px 30px',
                                    },
                                }}
                                className="shadow-card min-w-[290px] "
                            >
                                <Menu
                                    selectedKeys={selectedKeys}
                                    onClick={({ key }) => navigate({ to: key })}
                                    items={menuItems}
                                    mode="inline"
                                />
                            </Card>
                            <Card
                                className="shadow-card w-full"
                                styles={{
                                    body: {
                                        padding: '35px 30px',
                                    },
                                }}
                            >
                                <Outlet />
                            </Card>
                        </Flex>
                    </Card>
                </Container>
            </Content>
        </Layout>
    )
}
