import { SyncOutlined } from '@ant-design/icons'
import { createFileRoute } from '@tanstack/react-router'
import {
    Avatar,
    Button,
    Card,
    Flex,
    Form,
    Input,
    Modal,
    ModalProps,
    Result,
    Table,
    Tabs,
    Tag,
    theme,
    Typography,
} from 'antd'
import React, { useState } from 'react'
import { useCandidates, useCreateCandidate } from '../hooks'
import { FormItem } from '../components/form-item'
import { CreateCandidateInput } from '../zod/candidate'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

export const Route = createFileRoute('/_auth/campaigns/$campaignId/candidates')({
    component: CampaignCandidates,
})

function CampaignCandidates() {
    const { t } = useTranslation()
    const params = Route.useParams()
    const { token } = theme.useToken()
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const candidates = useCandidates(params.campaignId)
    return (
        <>
            <Card className="shadow-sm">
                <Flex
                    justify="space-between"
                    align="center"
                    style={{ marginBottom: token.marginMD }}
                >
                    <Typography.Title
                        className="mb-0 font-normal"
                        level={4}
                    >
                        {t('Candidates')}
                    </Typography.Title>
                    <Button
                        ghost
                        onClick={() => setCreateModalOpen(true)}
                        type="primary"
                    >
                        {t('Add candidates')}
                    </Button>
                </Flex>
                <Table
                    loading={candidates.isPending}
                    rowSelection={rowSelection}
                    columns={[
                        {
                            title: '',
                            dataIndex: 'id',
                            width: 60,
                            render(text) {
                                return <Avatar src={'https://api.dicebear.com/7.x/miniavs/svg?seed=' + text} />
                            },
                        },
                        {
                            title: t('Name'),
                            dataIndex: 'name',
                        },
                        {
                            title: t('Phone'),
                            dataIndex: 'phone',
                            width: 200,
                            render(text) {
                                return <Typography.Link href={`tel://${text}`}>{text}</Typography.Link>
                            },
                        },
                        {
                            title: t('Status'),
                            dataIndex: 'status',
                            width: 150,
                            render(text) {
                                return <CandidateStatusTag status={text} />
                            },
                        },
                    ]}
                    dataSource={candidates.data}
                />
                <CreateCandidateModal
                    open={createModalOpen}
                    onCancel={() => setCreateModalOpen(false)}
                />
            </Card>
        </>
    )
}

type CandidateStatus = 'new' | 'in_contact' | 'suspect' | 'success' | 'error'

function CandidateStatusTag({ status }: { status: CandidateStatus }) {
    const { t } = useTranslation()
    switch (status) {
        case 'new':
            return <Tag color="processing">{t('New')}</Tag>
        case 'in_contact':
            return (
                <Tag
                    icon={<SyncOutlined spin />}
                    color="processing"
                >
                    {t('Being contacted')}
                </Tag>
            )
        case 'suspect':
            return <Tag color="warning">{t('Suspect')}</Tag>
        case 'success':
            return <Tag color="success">{t('Positive')}</Tag>
        case 'error':
            return <Tag color="error">{t('Negative')}</Tag>
    }
}

function CreateCandidateModal({ onCancel, ...rest }: ModalProps) {
    const { t } = useTranslation()

    const items = [
        {
            label: t('Manual'),
            key: 'manual',
            children: <ManualCandidate onCancel={onCancel} />,
        },
        {
            label: t('Import'),
            key: 'import',
            children: <NotAvailable />,
        },
        {
            label: t('Automate'),
            key: 'automate',
            children: <NotAvailable />,
        },
    ]
    return (
        <Modal
            {...rest}
            onCancel={onCancel}
            footer={<></>}
        >
            <Tabs
                className="-ml-6"
                tabPosition="left"
                items={items}
            />
        </Modal>
    )
}

function NotAvailable() {
    const { t } = useTranslation()

    return <Result title={t('Not yet available')} />
}

function ManualCandidate({ onCancel }: { onCancel: ModalProps['onCancel'] }) {
    const { t } = useTranslation()

    const { campaignId } = Route.useParams()
    const [form] = Form.useForm<CreateCandidateInput>()
    const createCandidate = useCreateCandidate(campaignId, form)

    return (
        <Form<CreateCandidateInput>
            form={form}
            name="basic"
            layout="vertical"
            style={{ minWidth: 300 }}
            onFinish={(input) =>
                createCandidate.mutateAsync(input).then(() => {
                    if (onCancel) {
                        // return onCancel({} as any);
                    }
                })
            }
            autoComplete="off"
        >
            <FormItem<CreateCandidateInput>
                label={t('Name')}
                name="name"
                required
            >
                <Input />
            </FormItem>
            <FormItem<CreateCandidateInput>
                label={t('Phone')}
                name="phone"
                required
            >
                <Input />
            </FormItem>

            <FormItem className="mb-0">
                <Button
                    loading={createCandidate.isPending}
                    type="primary"
                    block
                    htmlType="submit"
                >
                    {t('Create')}
                </Button>
            </FormItem>
        </Form>
    )
}
