import { ArrowRightOutlined, BellOutlined, LoadingOutlined, SettingOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { createFileRoute, Link, Outlet } from '@tanstack/react-router'
import { Avatar, Button, Card, Flex, List, Result, Spin, theme, Typography } from 'antd'
import React from 'react'
import { useUser } from '../hooks'
import { Container } from '../components/layout/container'
import { PageHeader } from '../components/page-header'
import { useTranslation } from 'react-i18next'
import { UserAvatar } from '../user/components/user-avatar'
import { PortaledBreadcrumb } from '../components/portaled-breadcrumb'
import axios, { AxiosError } from 'axios'
import { NetworkErrors } from '../components/network-errors'

export const Route = createFileRoute('/_auth/users/$userId/')({
    component: ShowUser,
})

function ShowUser() {
    const { t } = useTranslation()
    const { userId } = Route.useParams()
    const user = useUser(userId)
    const { token } = theme.useToken()

    if (user.isPending || user.isError) {
        return (
            <NetworkErrors
                query={user}
                result404={{
                    title: t('User not found'),
                    extra: (
                        <Link to="/users">
                            <Button type="primary">{t('Users')}</Button>
                        </Link>
                    ),
                }}
            />
        )
    }

    return (
        <>
            <PortaledBreadcrumb
                items={[
                    {
                        title: <Link to="/users">{t('Users')}</Link>,
                    },
                    {
                        title: user.data.name,
                    },
                ]}
            />
            <PageHeader
                prefix={<UserAvatar user={user.data} />}
                title={user.data.name}
            />
            <Container className="py-6">
                <Flex
                    align="start"
                    gap="middle"
                >
                    <Card className="shadow-sm w-5/12">
                        <Flex justify="space-between">
                            <Flex
                                gap="small"
                                style={{ marginBottom: token.marginSM }}
                            >
                                <BellOutlined className="text-xl" />
                                <Typography.Title
                                    className="mb-0"
                                    level={5}
                                >
                                    {t('Activities')}
                                </Typography.Title>
                            </Flex>

                            <Link to="/campaigns">
                                {t('View all')} <ArrowRightOutlined />
                            </Link>
                        </Flex>
                        <List
                            itemLayout="horizontal"
                            dataSource={[
                                {
                                    title: t('Candidate contacted'),
                                    description: 'Jamie Schouten',
                                    avatar: <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=jamie" />,
                                },
                                {
                                    title: t('Campaign Started'),
                                    avatar: <WhatsAppOutlined />,
                                },
                                {
                                    title: t('Candidate added'),
                                    description: 'Jamie Schouten',
                                    avatar: <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=jamie" />,
                                },
                                {
                                    title: t('Campaign created'),
                                    avatar: <WhatsAppOutlined />,
                                },
                            ]}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={item.avatar}
                                        title={<a href="https://ant.design">{item.title}</a>}
                                        description={item.description}
                                    />
                                    <Typography.Text type="secondary">9 days ago</Typography.Text>
                                </List.Item>
                            )}
                        />
                    </Card>
                    <Flex
                        vertical
                        className="w-7/12"
                        gap="middle"
                    >
                        <Card className="shadow-sm">
                            <Flex
                                gap="small"
                                style={{ marginBottom: token.marginSM }}
                            >
                                <SettingOutlined className="text-xl" />
                                <Typography.Title
                                    className="mb-0"
                                    level={5}
                                >
                                    {t('Options')}
                                </Typography.Title>
                            </Flex>
                            <Flex gap="middle">
                                <Button danger>{t('Block access')}</Button>
                                <Button>{t('Forgot password')}</Button>
                            </Flex>
                        </Card>
                    </Flex>
                </Flex>
            </Container>
        </>
    )
}
