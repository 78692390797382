import { z } from "zod";
import { role } from "./user";

export const loginResponse = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
});

export const claims = z.object({
    iss: z.string(),
    sub: z.string(),
    aud: z.string(),
    exp: z.number(),
});

export const accessClaims = z
    .object({
        user: z.object({
            id: z.string(),
            name: z.string(),
            email: z.string(),
            role,
            emailVerifiedAt: z.coerce.date(),
            createdAt: z.coerce.date(),
            updatedAt: z.coerce.date(),
        }),
    })
    .and(claims);

export const loginInput = z.object({
    email: z.string(),
    password: z.string(),
    remember: z.boolean(),
});

export type LoginInput = z.infer<typeof loginInput>;
export type LoginResponse = z.infer<typeof loginResponse>;
export type Claims = z.infer<typeof claims>;
export type AccessClaims = z.infer<typeof accessClaims>;
