import { LockOutlined } from '@ant-design/icons'
import { Link, createFileRoute } from '@tanstack/react-router'
import { Button, Card, Form, Input, Result, Tooltip, Typography } from 'antd'
import React from 'react'
import { z } from 'zod'
import { useResetPassword } from '../hooks'
import { FormItem } from '../components/form-item'
import { isValidationError } from '../util'
import { ResetInput } from '../zod/reset'
import { useTranslation } from 'react-i18next'
const { Text, Title } = Typography

export const Route = createFileRoute('/_guest/reset')({
    validateSearch: z.object({
        token: z.string(),
    }),
    component: ResetComponent,
})

function ResetComponent() {
    const { t } = useTranslation()
    const search = Route.useSearch()
    const [form] = Form.useForm()

    const resetPassword = useResetPassword(search.token, form)

    const password = Form.useWatch('password', form)
    const confirmed = Form.useWatch('confirm', form)

    const passwordConfirmed = password && confirmed && password === confirmed

    if (resetPassword.isSuccess) {
        return (
            <Result
                title={t('Your password has been reset')}
                subTitle={t('You can now login with the password you just created.')}
                status="success"
                extra={
                    <Link to="/login">
                        <Button
                            size="large"
                            type="primary"
                        >
                            {t('Login')}
                        </Button>
                    </Link>
                }
            />
        )
    }

    if (resetPassword.isError && !isValidationError(resetPassword)) {
        return (
            <Result
                title={t('Failed to reset password')}
                subTitle={t('Most likely your link has expired, use the button below to try again')}
                status="error"
                extra={
                    <Link to="/forgot">
                        <Button
                            size="large"
                            type="primary"
                        >
                            {t('Try again')}
                        </Button>
                    </Link>
                }
            />
        )
    }

    return (
        <Card>
            <div className="mb-6">
                <Title>Reset password</Title>
                <Text>
                    {t('Enter your password below and then confirm the password')}
                    <br />
                    {t('by typing it again')}
                </Text>
            </div>
            <Form
                form={form}
                name="basic"
                layout="vertical"
                style={{ minWidth: 300 }}
                onFinish={resetPassword.mutate}
                autoComplete="off"
            >
                <FormItem<ResetInput>
                    label="Password"
                    name="password"
                    required
                >
                    <Input.Password
                        placeholder="Password"
                        prefix={<LockOutlined />}
                    />
                </FormItem>
                <FormItem<ResetInput>
                    label="Confirm password"
                    name="confirm"
                    required
                >
                    <Input.Password
                        placeholder="Password"
                        prefix={<LockOutlined />}
                    />
                </FormItem>

                <FormItem className="mb-0">
                    <Tooltip title={!passwordConfirmed ? t('Password does not match with confirmation') : undefined}>
                        <Button
                            loading={resetPassword.isPending}
                            block
                            disabled={!passwordConfirmed}
                            type="primary"
                            htmlType="submit"
                        >
                            {t('Reset password')}
                        </Button>
                    </Tooltip>
                    <div className="mt-5 justify-center flex space-x-1">
                        <Text>{t('Remember you password?')}</Text>
                        <Link
                            to="/login"
                            className="text-primary"
                        >
                            {t('Log in instead')}
                        </Link>
                    </div>
                </FormItem>
            </Form>
        </Card>
    )
}
