import { Link, createFileRoute } from '@tanstack/react-router'
import { Button, Card, Form, Input, Result, Typography } from 'antd'
import React from 'react'
import { useForgotPassword } from '../hooks'
import { FormItem } from '../components/form-item'
import { addValidationOnError, isValidationError } from '../util'
import { LoginInput } from '../zod/login'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
const { Text, Title } = Typography

export const Route = createFileRoute('/_guest/forgot')({
    component: ForgotComponent,
})

function ForgotComponent() {
    const forgotPassword = useForgotPassword()
    const [form] = Form.useForm()

    if (forgotPassword.isSuccess) {
        return (
            <Result
                title={t('Password reset instructions sent')}
                subTitle={t('If an email does not arrive within a few minutes, check the spelling and try again')}
                status="success"
                extra={
                    <Link to="/login">
                        <Button
                            size="large"
                            type="primary"
                        >
                            {t('Back to sign in')}
                        </Button>
                    </Link>
                }
            />
        )
    }

    if (forgotPassword.isError && !isValidationError(forgotPassword)) {
        return (
            <Result
                title={t('Failed to send instructions')}
                subTitle={t('Try again in a little bit')}
                status="error"
            />
        )
    }

    return (
        <Card>
            <div className="mb-6">
                <Title>{t('Forgot password')}</Title>
                <Text>
                    <Trans
                        defaults="Please enter your email below, and we will send you<br />instructions on how to reset your password."
                        components={{
                            br: <br />,
                        }}
                    />
                </Text>
            </div>
            <Form
                form={form}
                name="basic"
                layout="vertical"
                style={{ minWidth: 300 }}
                onFinish={(input) => forgotPassword.mutateAsync(input).catch(addValidationOnError(form))}
                autoComplete="off"
            >
                <FormItem<LoginInput>
                    label="Email"
                    name="email"
                    required
                >
                    <Input
                        type="email"
                        placeholder="Email"
                    />
                </FormItem>

                <FormItem className="mb-0">
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={forgotPassword.isPending}
                    >
                        {t('Send instructions')}
                    </Button>
                    <div className="mt-5 justify-center flex space-x-1">
                        <Text>{t('Remember your password?')}</Text>
                        <Link
                            className="float-right text-primary"
                            to="/login"
                        >
                            {t('sign in, instead')}
                        </Link>
                    </div>
                </FormItem>
            </Form>
        </Card>
    )
}
