import { UseMutationResult } from "@tanstack/react-query";
import { Form, FormProps } from "antd";
import { addValidationOnError } from "../util";

export default function useForm({
    mutation,
    onSuccess,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutation: UseMutationResult<any, any, any, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess?: (value: any) => void;
}): FormProps {
    const [form] = Form.useForm();

    // const onValuesChange = (values: Record<string, unknown>) => {
    //     Object.keys(values).forEach((field) => {
    //         const error = form.getFieldError(field)

    //         if (!error.length) {
    //             return
    //         }

    //         form.setFields([
    //             {
    //                 name: field,
    //                 errors: [],
    //             },
    //         ])
    //     })
    // }

    return {
        form,
        onFinish(attributes) {
            mutation
                .mutateAsync(attributes)
                .then((value) => {
                    onSuccess?.(value);
                })
                .catch(addValidationOnError(form));
        },
        // onValuesChange,
        // onKeyDown: (event) => {
        //     if (event.key === 'Enter' && event.metaKey) {
        //         form.submit()
        //     }
        // },
    };
}
