import { Link, createFileRoute } from '@tanstack/react-router'
import { Button, Result, Spin } from 'antd'
import React from 'react'
import { z } from 'zod'
import { useVerify } from '../hooks'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_guest/verify')({
    validateSearch: z.object({
        token: z.string(),
    }),
    component: VerifyComponent,
})

function VerifyComponent() {
    const { t } = useTranslation()
    const search = Route.useSearch()

    const verify = useVerify(search.token)

    if (verify.isSuccess) {
        return (
            <Result
                title={t('Your email address has been verified')}
                subTitle={t('Thank you for register, login to get started')}
                status="success"
                extra={
                    <Link to="/login">
                        <Button
                            size="large"
                            type="primary"
                        >
                            {t('Back to login')}
                        </Button>
                    </Link>
                }
            />
        )
    }

    if (verify.isError) {
        return (
            <Result
                title={t('Failed to verify')}
                subTitle={t('Most likely you have already use this link before')}
                status="error"
                extra={
                    <Link to="/login">
                        <Button
                            size="large"
                            type="primary"
                        >
                            {t('Back to login')}
                        </Button>
                    </Link>
                }
            />
        )
    }

    return <Spin spinning />
}
