import React from "react";
import { ReactNode } from "@tanstack/react-router";

export const containerWidthClass = `max-w-[1280px]`;

export function Container({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={`${containerWidthClass} mx-auto ${className}`}>
      {children}
    </div>
  );
}
