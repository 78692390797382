import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { CreateCampaign } from "../components/create-campaign";

export const Route = createFileRoute("/_auth/campaigns/create")({
  component: CampaignsCreate,
});

function CampaignsCreate() {
  return <CreateCampaign />;
}
