import { Link } from '@tanstack/react-router'
import { Avatar, List, theme } from 'antd'
import { RoleTag } from './role-tag'
import React from 'react'
import { SearchResponse } from '../../api'
import { SearchProps } from '../../components/search'
import { UserAvatar } from './user-avatar'
import { UserLink } from './user-link'

export type UserSearchListProps = {
    users: SearchResponse['users']
} & Pick<SearchProps, 'onCancel'>

export function UserSearchList({ users, onCancel }: UserSearchListProps) {
    const { token } = theme.useToken()

    return (
        <List
            header={<span style={{ padding: `0 ${token.paddingMD}px` }}>Users</span>}
            dataSource={users}
            renderItem={(user) => {
                return (
                    <UserLink
                        id={user.id}
                        onClick={onCancel}
                    >
                        <List.Item
                            tabIndex={0}
                            style={{
                                padding: `${token.paddingSM}px ${token.paddingMD}px`,
                            }}
                            className="cursor-pointer"
                            extra={<RoleTag role={user.role}></RoleTag>}
                        >
                            <List.Item.Meta
                                avatar={<UserAvatar user={user} />}
                                title={user.name}
                                description={user.email}
                            />
                        </List.Item>
                    </UserLink>
                )
            }}
        />
    )
}
