import { Badge } from 'antd'
import React from 'react'
import { Status } from '../status'

export type CampaignBadgeProps = {
    status: Status
}

export function CampaignBadge({ status }: CampaignBadgeProps) {
    return (
        <Badge
            className="lg-badge"
            color={status === 'active' ? 'green' : 'gray'}
        />
    )
}
