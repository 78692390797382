import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link, useNavigate } from '@tanstack/react-router'
import { Button, Card, Flex, Form, FormInstance, Input, Steps, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import {
    useCampaignTraining,
    useCampaignTrainings,
    useCreateCampaign,
    useCreateCampaignTraining,
    useUpdateCampaign,
    useUpdateCampaignTraining,
} from '../hooks'
import { Chat } from './chat/chat'
import { FormItem } from './form-item'
import { addValidationOnError } from '../util'
import { Campaign } from '../zod/campaign'
import { Message } from '../zod/message'
import { generateCampaignTrainingUserAnswer } from '../api'
import { Container } from './layout/container'

export type CreateCampaignFields = {
    offer: string
    title: string
}

type CreateCampaignStep = 'describe' | 'train' | 'finish'

const createCampaignSteps: CreateCampaignStep[] = ['describe', 'train', 'finish']
export type CreateCampaignProps = {
    initialStep?: number
    initialCampaign?: Campaign
}

export function CreateCampaign({ initialStep = 0, initialCampaign }: CreateCampaignProps) {
    const [form] = Form.useForm()
    const [campaign] = useState<Campaign | undefined>(initialCampaign)
    const [current, setCurrent] = useState(initialStep)

    const nextStep = () => setCurrent(current + 1)
    const previousStep = () => setCurrent(current - 1)
    const currentStep = createCampaignSteps[current]

    const stepProps = {
        form,
        campaign,
        currentStep,
        previousStep,
        nextStep,
    }

    return (
        <Container>
            <Flex
                gap="large"
                vertical
            >
                <span>
                    <Link to="/campaigns">
                        <ArrowLeftOutlined /> Back to overview
                    </Link>
                    <Typography.Title className="mb-0">Create a campaign</Typography.Title>
                </span>

                <Steps
                    current={current}
                    onChange={(clicked) => {
                        if (clicked >= current) {
                            return
                        }

                        setCurrent(clicked)
                    }}
                    items={[
                        {
                            title: 'Describe offer',
                        },
                        {
                            title: 'Train',
                            disabled: current <= 1,
                        },
                        {
                            title: 'Finish',
                            disabled: current <= 2,
                        },
                    ]}
                />

                <Form<CreateCampaignFields>
                    form={form}
                    initialValues={{
                        title: campaign?.title,
                        offer: campaign?.offer,
                    }}
                    layout="vertical"
                >
                    <DescribeStep {...stepProps} />
                    <TrainStep {...stepProps} />
                    <FinishStep {...stepProps} />
                </Form>
            </Flex>
        </Container>
    )
}

type StepProps = {
    form: FormInstance<CreateCampaignFields>
    campaign?: Campaign
    currentStep: CreateCampaignStep
    nextStep: () => void
    previousStep: () => void
}

function DescribeStep({ currentStep, nextStep, campaign, form }: StepProps) {
    const navigate = useNavigate()
    const offer = Form.useWatch('offer', form)
    const title = Form.useWatch('title', form)

    const describeStepCompleted = offer?.trim().length >= 200 && title
    const createCampaign = useCreateCampaign()

    return (
        <Card hidden={currentStep !== 'describe'}>
            <Flex gap={45}>
                <div className="w-5/12">
                    <Typography.Title level={2}>Describe offer</Typography.Title>
                    <Typography.Paragraph>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lacinia blandit neque ut
                        ultricies. Pellentesque auctor lacinia metus ac semper. Ut fermentum, libero ac rhoncus viverra,
                        est diam elementum eros, ut consectetur odio leo nec neque. Curabitur lobortis elit nisi, ac
                        semper dui rutrum vitae. Donec sit amet purus in enim rutrum tempus. Nunc ac massa at ipsum
                        molestie eleifend nec in ipsum. Sed luctus lacus ut est lobortis venenatis. Fusce malesuada
                        convallis nisi sit amet porta. Class aptent taciti sociosqu ad litora torquent per conubia
                        nostra, per inceptos himenaeos. Praesent nibh quam, pulvinar at libero quis, eleifend
                        consectetur mauris.
                    </Typography.Paragraph>
                    <Flex
                        align="end"
                        vertical
                    >
                        <Tooltip
                            title={
                                !describeStepCompleted &&
                                'Give your campaign a title, and fill at least 200 characters in the job offer content before starting training'
                            }
                        >
                            <Button
                                disabled={!describeStepCompleted}
                                loading={createCampaign.isPending}
                                onClick={() => {
                                    if (campaign?.id) {
                                        nextStep()
                                        return
                                    }

                                    createCampaign
                                        .mutateAsync(form.getFieldsValue())
                                        .then((campaign) => {
                                            navigate({
                                                to: '/campaigns/$campaignId',
                                                params: {
                                                    campaignId: campaign.id,
                                                },
                                            })
                                        })
                                        .catch(addValidationOnError(form))
                                }}
                                type="primary"
                            >
                                Start training
                            </Button>
                        </Tooltip>
                    </Flex>
                </div>

                <div className="w-7/12">
                    <FormItem<CreateCampaignFields>
                        required
                        label="Title"
                        name="title"
                    >
                        <Input></Input>
                    </FormItem>

                    <FormItem<CreateCampaignFields>
                        required
                        label="Job offer content"
                        name="offer"
                        rules={[{ validator: () => Promise.resolve() }]}
                    >
                        <Input.TextArea
                            showCount
                            minLength={200}
                            rows={27}
                        />
                    </FormItem>
                </div>
            </Flex>
        </Card>
    )
}

function TrainStep({ currentStep, nextStep, campaign, previousStep }: StepProps) {
    const [messages, setMessages] = useState<Message[]>([])
    const trained = messages.length > 4

    const createCampaignTraining = useCreateCampaignTraining(campaign?.id)
    const trainings = useCampaignTrainings(campaign?.id)
    const training = useCampaignTraining(campaign?.id, trainings?.data ? trainings.data[0]?.id : undefined)
    const updateCampaignTraining = useUpdateCampaignTraining(campaign?.id, training.data?.id)

    useEffect(() => {
        setMessages(training.data?.conversation?.messages || [])
    }, [training.data?.id, training.data?.conversation?.messages])

    return (
        <Card hidden={currentStep !== 'train'}>
            <Flex gap={45}>
                <div className="w-5/12">
                    <Typography.Title level={2}>Train</Typography.Title>
                    <Typography.Paragraph>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lacinia blandit neque ut
                        ultricies. Pellentesque auctor lacinia metus ac semper. Ut fermentum, libero ac rhoncus viverra,
                        est diam elementum eros, ut consectetur odio leo nec neque. Curabitur lobortis elit nisi, ac
                        semper dui rutrum vitae. Donec sit amet purus in enim rutrum tempus. Nunc ac massa at ipsum
                        molestie eleifend nec in ipsum. Sed luctus lacus ut est lobortis venenatis. Fusce malesuada
                        convallis nisi sit amet porta. Class aptent taciti sociosqu ad litora torquent per conubia
                        nostra, per inceptos himenaeos. Praesent nibh quam, pulvinar at libero quis, eleifend
                        consectetur mauris.
                    </Typography.Paragraph>
                    <Flex
                        align="end"
                        vertical
                    >
                        <Flex
                            align="end"
                            gap="small"
                        >
                            <Button onClick={previousStep}>Back</Button>
                            <Tooltip
                                title={!trained && 'Finish training before continuing, follow the instructions in chat'}
                            >
                                <Button
                                    disabled={!trained}
                                    onClick={nextStep}
                                    type="primary"
                                >
                                    Finish training
                                </Button>
                            </Tooltip>
                        </Flex>
                    </Flex>
                </div>
                <Chat
                    generateUserAnswer={() =>
                        generateCampaignTrainingUserAnswer(campaign?.id || '', training.data?.id || '')
                    }
                    className="w-7/12"
                    messages={messages}
                    disabled={createCampaignTraining.isPending || updateCampaignTraining.isPending}
                    placeholder="Send message"
                    onMessageAdded={async (input) => {
                        const trimmed = input.trim()
                        if (trimmed.length < 1) {
                            return
                        }

                        setMessages((messages) => [
                            ...messages,
                            {
                                id: (Math.random() + 1).toString(36).substring(7),
                                role: 'user',
                                content: trimmed,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            },
                        ])

                        return updateCampaignTraining
                            .mutateAsync({
                                message: input,
                            })
                            .then((message) => {
                                setMessages((messages) => [...messages, message])
                            })
                    }}
                />
            </Flex>
        </Card>
    )
}

function FinishStep({ currentStep, previousStep, campaign }: StepProps) {
    const updateCampaign = useUpdateCampaign(campaign?.id)
    return (
        <Card hidden={currentStep !== 'finish'}>
            <Flex gap={45}>
                <div className="w-5/12">
                    <Typography.Title level={2}>Finish creation</Typography.Title>
                    <Typography.Paragraph>
                        Sed imperdiet blandit magna non sagittis. Suspendisse potenti. Etiam posuere hendrerit lectus,
                        sed suscipit arcu dictum sed. Nam commodo massa in vehicula vulputate. Nulla pellentesque porta
                        diam eu rutrum. Fusce eget auctor erat. Duis at tempus magna. Integer lacinia ullamcorper
                        luctus.
                    </Typography.Paragraph>
                    <Flex
                        align="end"
                        vertical
                    >
                        <Flex
                            align="end"
                            gap="small"
                        >
                            <Button onClick={previousStep}>Back</Button>
                            <Button
                                loading={updateCampaign.isPending}
                                onClick={() =>
                                    updateCampaign.mutate({
                                        status: 'inactive',
                                    })
                                }
                                type="primary"
                            >
                                Create campaign
                            </Button>
                        </Flex>
                    </Flex>
                </div>
                <div className="w-7/12" />
            </Flex>
        </Card>
    )
}
