import { createFileRoute } from '@tanstack/react-router'
import { Breadcrumb, Button, Flex, Typography } from 'antd'
import React from 'react'
import { PortaledBreadcrumb } from '../components/portaled-breadcrumb'
import { usePagination } from '../hooks/use-pagination'
import { searchQuery } from '../zod/pagination'
import { useTranslation } from 'react-i18next'
import { useUsers } from '../user/hooks'
import { UserTable } from '../user/components/user-table'
import { MainLayout } from '../components/layout/main-layout'

export const Route = createFileRoute('/_auth/users/')({
    validateSearch: searchQuery,
    component: Users,
})

function Users() {
    const { t } = useTranslation()
    const search = Route.useSearch()

    const tableProps = usePagination(useUsers, { query: search })

    return (
        <>
            <PortaledBreadcrumb
                items={[
                    {
                        title: t('Users'),
                    },
                ]}
            />
            <Flex
                justify="space-between"
                align="center"
                className="mb-4"
            >
                <Typography.Title
                    level={4}
                    className="mb-0"
                >
                    {t('Users')}
                </Typography.Title>
                <Button
                    size="large"
                    type="primary"
                >
                    {t('Add user')}
                </Button>
            </Flex>
            <UserTable {...tableProps} />
        </>
    )
}
