import { createFileRoute } from "@tanstack/react-router";
import React from "react";

export const Route = createFileRoute("/_auth/campaigns/$campaignId/overview")({
  component: CampaignOverview,
});

function CampaignOverview() {
  return <></>;
}
