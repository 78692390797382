import { useMutation, UseMutationOptions, useQuery, useQueryClient } from '@tanstack/react-query'
import { UpdateInput } from './input/update-input'
import { updateCampaign, fetchCampaigns, deleteCampaign } from './api'

export function useUpdateCampaign(campaignId?: string) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: UpdateInput) => updateCampaign(campaignId!, data),
        onSuccess: (campaign) => {
            queryClient.invalidateQueries({
                queryKey: ['campaigns'],
                exact: true,
            })

            queryClient.invalidateQueries({
                queryKey: ['search'],
            })
        },
    })
}

export function useDeleteCampaign() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: deleteCampaign,
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: ['campaigns'],
                exact: true,
            })
        },
    })
}

export function useCampaigns(...args: Parameters<typeof fetchCampaigns>) {
    return useQuery({
        queryFn: () => fetchCampaigns(...args),
        queryKey: ['campaigns'],
    })
}
