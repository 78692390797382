import { z } from 'zod'
import { paginatedSearchQuery } from '../zod/pagination'
import { status } from './status'

export const campaign = z.object({
    id: z.string(),
    title: z.string(),
    status,
    vacancy: z.string(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
})

export type Campaign = z.infer<typeof campaign>

export const campaignQuery = paginatedSearchQuery({
    filters: z.object({
        title: z.string().array().optional(),
    }),
    sort: z.enum(['title', '-title']),
})

export type CampaignQuery = z.infer<typeof campaignQuery>
