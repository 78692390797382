import { TablePaginationConfig, TableProps } from 'antd'
import { ColumnType, FilterValue, SorterResult } from 'antd/es/table/interface'
import { SearchQuery } from '../zod/pagination'
import { useNavigate } from '@tanstack/react-router'
import { decamelize } from 'humps'
import { DefinedQueryObserverResult } from '@tanstack/react-query'

export type Filters = Record<string, FilterValue | null>

export type UsePaginationTableProps<RecordType> = TableProps<RecordType> & {
    filters: Filters
    withColumnDefaults: (columns: ColumnType<RecordType>[]) => ColumnType<RecordType>[]
}

const defaultPage = 1
const defaultPageSize = 10

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InferSearch<T> = T extends (input: infer I) => any ? I : never
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InferReturn<T> = T extends (input: any) => infer I
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      I extends DefinedQueryObserverResult<{ data: any[] }>
        ? I['data']['data'][0]
        : never
    : never

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePagination<K extends (...args: any) => any>(
    query: K,
    search: InferSearch<K>
): UsePaginationTableProps<InferReturn<K>> {
    const navigate = useNavigate()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pagination = (search as any).query as SearchQuery
    const current = pagination.page || defaultPage
    const pageSize = pagination.size || defaultPageSize
    const sort = pagination.sort || ''
    const filters = retrieveFilterParam()

    const onChange = (
        { current, pageSize }: TablePaginationConfig,
        filters: Filters,
        sorter: SorterResult<InferReturn<K>> | SorterResult<InferReturn<K>>[]
    ) => {
        let sort = ''

        if (!Array.isArray(sorter) && sorter.field && sorter.order) {
            const symbol = sorter.order === 'descend' ? '-' : ''
            sort = symbol + decamelize(sorter.field.toString())
        }

        navigate({
            search: () => ({
                page: current ?? defaultPage,
                size: pageSize ?? defaultPageSize,
                sort: sort,
                ...buildFilters(filters),
            }),
        })
    }

    const { data, isPending, isPaused } = query({
        ...search,
        pagination: {
            size: defaultPageSize,
            ...pagination,
            filters: {
                ...filters,
                ...pagination.filters,
            },
        },
    })

    return {
        onChange,
        filters,
        dataSource: (data?.data || []) as InferReturn<K>[],
        loading: isPending && !isPaused,
        pagination: {
            current,
            defaultPageSize: pageSize,
            pageSize: data?.meta.perPage || pageSize,
            total: data?.meta.total,
            showSizeChanger: true,
        },
        withColumnDefaults(columns) {
            return columns.map((column) => {
                if (sort && sort.startsWith('-') && sort.substring(1) === column.key) {
                    column.sortOrder = 'descend'
                }

                if (sort && sort === column.key) {
                    column.sortOrder = 'ascend'
                }

                const key = column.key as string

                if (key && (key as string) in filters) {
                    column.filteredValue = filters[key] ? filters[key] : null
                } else {
                    column.filteredValue = null
                }

                return column
            })
        },
    }
}

export function buildFilters(filters: Filters): Record<string, string> {
    return Object.keys(filters).reduce(
        (carry, key) => {
            const value = filters[key]

            if (!value) {
                return carry
            }

            carry[`filter[${key}]`] = value.join(',')

            return carry
        },
        {} as Record<string, string>
    )
}

function retrieveFilterParam(): Filters {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    return Object.keys(params)
        .filter((param) => param.startsWith('filter'))
        .reduce((carry, param) => {
            const matches = param.match(/\[(.*?)]/)

            if (!matches) {
                return carry
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            carry[matches[1]] = params[param].split(',')

            return carry
        }, {})
}
