import { z } from 'zod'
import {user} from "./user";

export const registerResponse = user

export const registerInput = z.object({
    name: z.string(),
    email: z.string(),
    password: z.string(),
    passwordConfirmation: z.string(),
    agreement: z.boolean(),
})

export type RegisterInput = z.infer<typeof registerInput>
export type RegisterResponse = z.infer<typeof registerResponse>
